import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { updateNestedObjByPath } from "utils";
import type {
  NewMessageRequest,
  NewTemplatedMessageRequest,
} from "services/new-message-service";
import type { SupportedLocale } from "components/supported-language";
import type { StandardObject } from "utils";

type NewMessageDestination = "email" | "sms" | "pns";
type NewMessageContent = "title" | "content";

interface NewMessageState {
  deliveryMethod: NewMessageDestination;
  inBatch: boolean;
  fromTemplate: boolean;
  destinations: Record<NewMessageDestination, string>;
  content: Record<NewMessageDestination, Record<NewMessageContent, string>>;
  template: {
    id: string;
    name: string;
    locale: SupportedLocale | "";
    keywordArguments: Record<string, any>;
  };
}

const initialState: NewMessageState = {
  inBatch: false,
  deliveryMethod: "sms",
  fromTemplate: false,
  destinations: {
    email: "",
    sms: "",
    pns: "",
  },
  content: {
    email: {
      title: "",
      content: "",
    },
    sms: {
      title: "",
      content: "",
    },
    pns: {
      title: "",
      content: "",
    },
  },
  template: {
    id: "",
    name: "",
    locale: "",
    keywordArguments: {},
  },
};
export const newMessageSlice = createSlice({
  name: "newMessage",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    updateState: (state, action: PayloadAction<StandardObject>) => {
      // Update state by path
      let newState = action.payload;
      Object.entries(newState).forEach(([fieldpath, v]) => {
        updateNestedObjByPath(state, fieldpath, v);
      });
    },
  },
});

const selectNewMessage = (state: { newMessage: NewMessageState }) =>
  state.newMessage;

export const selectNewMessageState = createSelector(
  [selectNewMessage],
  (s: NewMessageState) => {
    const deliveryMethod = s.deliveryMethod;
    return {
      deliveryMethod,
      inBatch: s.inBatch,
      fromTemplate: s.fromTemplate,
      destination: s.destinations[deliveryMethod],
      templateName: s.template.name,
      templateId: s.template.id,
      locale: s.template.locale,
      title: s.content[deliveryMethod].title,
      content: s.content[deliveryMethod].content,
    };
  }
);

export const selectPostMessageArgs = createSelector(
  [selectNewMessage],
  (s: NewMessageState) => {
    const deliveryMethod = s.deliveryMethod;
    const destination = {
      email: "email",
      sms: "phoneNumber",
      pns: "deviceId",
    }[deliveryMethod];

    const ret: NewMessageRequest = {
      recipient: { [destination]: s.destinations[deliveryMethod] },
      recipients: s.destinations[deliveryMethod]
        .split("\n")
        .map((d) => d.trim())
        .filter((d) => d.length)
        .map((d) => ({ [destination]: d })),
      messageContent: s.content[deliveryMethod].content,
      subject: s.content[deliveryMethod].title,
      content: s.content[deliveryMethod].content,
      contentType: "text/html",
      messageTitle: s.content[deliveryMethod].title,
    };
    return ret;
  }
);

export const selectPostTemplateMessageArgs = createSelector(
  [selectNewMessage],
  (s: NewMessageState) => {
    const deliveryMethod = s.deliveryMethod;
    const destination = {
      email: "email",
      sms: "phoneNumber",
      pns: "deviceId",
    }[deliveryMethod];

    if (s.template.locale !== "") {
      const ret: NewTemplatedMessageRequest = {
        recipient: { [destination]: s.destinations[deliveryMethod] },
        recipients: s.destinations[deliveryMethod]
          .split("\n")
          .map((d) => d.trim())
          .filter((d) => d.length)
          .map((d) => ({ [destination]: d })),
        templateName: s.template.name,
        locale: s.template.locale,
        keywordArguments: s.template.keywordArguments,
        contentType: "text/html",
        subjectKeywordArguments: s.template.keywordArguments,
        contentKeywordArguments: s.template.keywordArguments,
        titleKeywordArguments: s.template.keywordArguments,
      };
      return ret;
    }
  }
);

export const { updateState, resetState } = newMessageSlice.actions;

export default newMessageSlice.reducer;
