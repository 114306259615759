import {
  createTablePageWithEditedDetailSlice,
  createTablePageWithEditedDetailSelector,
} from "./common";

export const adminManagementSlice =
  createTablePageWithEditedDetailSlice("adminManagement");

export const selectAdminManagementState =
  createTablePageWithEditedDetailSelector("adminManagement");

export const { setQueryParams, saveEditedDetail, removeEditedDetail } =
  adminManagementSlice.actions;

export default adminManagementSlice.reducer;
