import React from "react";
import buildTree, { Tree, TreeNode } from "utils/tree";
import type { FileUploadData } from "components/drag-n-drop-upload";
export type StandardObject<T = any> = Record<string, T>;

interface DummyChangeEventTarget {
  files?: FileList;
  value?: any;
  type?: string;
  name?: string;
  checked?: boolean;
  fileupload?: EventTarget & FileUploadData;
  props?: StandardObject;
  label?: string;
}
interface DummyChangeEvent {
  action?: string;
  target: DummyChangeEventTarget;
}
export type CustomChangeEvent<T> = React.ChangeEvent<T> & { action?: string } | DummyChangeEvent;

export const parseJwt = <T = any>(token: string): T => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload) as T;
};

export const saveState = (groupName: string, state: StandardObject): void => {
  if (typeof window === "undefined") {
    return;
  }
  Object.entries(state).forEach(([k, v]) => {
    sessionStorage.setItem(`value/${groupName}/${k}`, v);
    sessionStorage.setItem(`type/${groupName}/${k}`, typeof v);
  });
};

export const loadState = (groupName: string, keys: string[]): StandardObject => {
  let state: StandardObject = {};
  if (typeof window === "undefined") {
    return state;
  }
  keys.forEach((k) => {
    let storedValue = sessionStorage.getItem(`value/${groupName}/${k}`);
    let storedType = sessionStorage.getItem(`type/${groupName}/${k}`); 
    if (storedValue && storedType) {
      if (storedType === "boolean") {
        if (storedValue === "true") {
          state[k] = true
        } else if (storedValue === "false") {
          state[k] = false
        } else {
          state[k] = undefined
        }
      } else {
        state[k] = storedValue;
      }
    }
  });
  return state;
};

export const removeState = (groupName: string, keys: string[]): void => {
  if (typeof window === "undefined") {
    return;
  }
  keys.forEach((k) => {
    try {
      sessionStorage.removeItem(`value/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state value/${groupName}/${k}: ${e}.`);
    }
    try {
      sessionStorage.removeItem(`type/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state type/${groupName}/${k}: ${e}.`);
    }
  });
};

export const assignObject = (oldValues: StandardObject, newValues: StandardObject): StandardObject => {
  // assign new values to existing values and return new object
  // undefined value could remove existing value
  let ret = Object.entries({ ...oldValues, ...newValues }).filter(
    ([k, v]) => v !== undefined
  );
  return Object.fromEntries(ret);
};

export const isScalarArrayEqual = (array1: any[], array2: any[]): boolean => {
  // https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function (value, index) {
        return value === array2Sorted[index];
      })
  );
};

interface AdminRoleItem {
  id: number;
  code: string;
  parentRoleId?: number;
  requiredRoleId?: number;
  order: number;
}

export const makeRoleTree = (d: AdminRoleItem[]): Tree => {
  const tree = buildTree();
  const rootId = -999;
  tree.setRoot(rootId, {
    code: "Admin Roles",
    isRole: false,
  });
  if (d.length > 0) {
    d.forEach((d) => {
      let parent;
      const pid = d.parentRoleId === undefined ? rootId : d.parentRoleId;
      if (tree.contains(pid)) {
        parent = tree.getNodeById(pid);
      } else {
        parent = tree.addNew(pid, {});
      }
      if (typeof parent === "undefined") {
        return
      }
      tree.addChildTo(parent, d.id, {
        code: d.code,
        order: d.order,
        requiredId: d.requiredRoleId ? d.requiredRoleId : null,
      });
    });
  }
  // Set state index
  tree.preOrder().forEach((n: TreeNode, idx: number) => {
    n.setProperty("stateIndex", idx);
  });
  return tree;
};

export const isEncryptedContent = (content: string): boolean => {
  if (!content) {
    return false;
  }
  return !!content.match(/<encrypted>(.*?)<\/encrypted>/g);
};

export const arrayIsEqual = (arr1: any[], arr2: any[]): boolean => {
  if (!(arr1 instanceof Array && arr2 instanceof Array)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arrayIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] instanceof Object && arr2[i] instanceof Object) {
      if (!jsonObjectIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const jsonObjectIsEqual = (obj1: unknown, obj2: unknown): boolean => {
  if (obj1 instanceof Array && obj2 instanceof Array) {
    return arrayIsEqual(obj1, obj2);
  } else if (!(obj1 instanceof Object && obj2 instanceof Object)) {
    return obj1 === obj2;
  }

  // Compare Objects
  const standObj1 = obj1 as StandardObject;
  const standObj2 = obj2 as StandardObject;
  const keys1 = Object.keys(standObj1);
  const keys2 = Object.keys(standObj2);

  if (!jsonObjectIsEqual(keys1, keys2)) {
    return false;
  }

  for (const propName of keys2) {
    if (!jsonObjectIsEqual(standObj1[propName], standObj2[propName])) {
      return false;
    }
  }
  return true;
};

export const getObjectUpdates = (origConf: StandardObject, newConf: StandardObject): StandardObject => {
  let updates = Object.entries(newConf).reduce<StandardObject>((sm, [k, v]) => {
    let oldValue = origConf[k];
    // const isModified = !jsonObjectIsEqual(oldValue, v);
    let isModified;
    if (
      typeof v === "object" &&
      typeof oldValue === "object" &&
      oldValue !== null &&
      oldValue !== undefined
    ) {
      // If new value is object and old value exist
      isModified = !jsonObjectIsEqual(oldValue, v);
    } else {
      isModified = oldValue !== v;
    }
    if (isModified) {
      if (v === "" || v === null) {
        sm[k] = null;
      } else if (v instanceof Array && !v.length) {
        sm[k] = null;
      } else {
        sm[k] = v;
      }
    }
    return sm;
  }, {});
  return updates;
};

export const checkIsUndefined = (val: unknown): boolean => typeof val === "undefined";

export const updateNestedObjByPath = (nestedObj: StandardObject, fieldpath: string, newValue: any): void => {
  // Update nestedObject in place, with path syntax a.b.c.d
  const paths = fieldpath.split(".");
  let iobj = newValue;
  if (paths.length > 1) {
    paths
      .slice()
      .reverse()
      .forEach((ipath, i) => {
        let jobj = nestedObj;
        if (i < paths.length - 1) {
          paths.forEach((jpath, j) => {
            if (j < paths.length - 1 - i) {
              jobj = jobj[jpath];
              if (typeof jobj === 'undefined') {
                console.error(`${jpath} not found when updating ${fieldpath} on:`, jobj);
              }
            }
          })
          jobj[ipath] = iobj;
          iobj = jobj;
        }
      });
  } else {
    nestedObj[paths[0]] = newValue;
  }
};

interface WindowDimensions {
  width: number;
  height: number;
}

export const getWindowDimensions = (): WindowDimensions => {
  if (typeof window === "undefined") {
    return {
      width: 0,
      height: 0,
    };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const getMimeTypeFromFileExtension = (fext: string): string => {
  const types: StandardObject<string> = {
    "html": "text/html",
    "css": "text/css",
    "xml": "text/xml",
    "gif": "image/gif",
    "jpg": "image/jpeg",
    "jpeg": "image/jpeg",
    "js": "application/x-javascript",
    "atom": "application/atom+xml",
    "rss": "application/rss+xml",
    "mml": "text/mathml",
    "txt": "text/plain",
    "jad": "text/vnd.sun.j2me.app-descriptor",
    "wml": "text/vnd.wap.wml",
    "htc": "text/x-component",
    "png": "image/png",
    "tiff": "image/tiff",
    "wbmp": "image/vnd.wap.wbmp",
    "ico": "image/x-icon",
    "jng": "image/x-jng",
    "bmp": "image/x-ms-bmp",
    "svg": "image/svg+xml",
    "webp": "image/webp",
    "jar": "application/java-archive",
    "hqx": "application/mac-binhex40",
    "doc": "application/msword",
    "pdf": "application/pdf",
    "ps": "application/postscript",
    "rtf": "application/rtf",
    "xls": "application/vnd.ms-excel",
    "ppt": "application/vnd.ms-powerpoint",
    "wmlc": "application/vnd.wap.wmlc",
    "kml": "application/vnd.google-earth.kml+xml",
    "kmz": "application/vnd.google-earth.kmz",
    "7z": "application/x-7z-compressed",
    "cco": "application/x-cocoa",
    "jardiff": "application/x-java-archive-diff",
    "jnlp": "application/x-java-jnlp-file",
    "run": "application/x-makeself",
    "pl": "application/x-perl",
    "pdb": "application/x-pilot",
    "rar": "application/x-rar-compressed",
    "rpm": "application/x-redhat-package-manager",
    "sea": "application/x-sea",
    "swf": "application/x-shockwave-flash",
    "sit": "application/x-stuffit",
    "tcl": "application/x-tcl",
    "pem": "application/x-x509-ca-cert",
    "xpi": "application/x-xpinstall",
    "xhtml": "application/xhtml+xml",
    "zip": "application/zip",
    "mid": "audio/midi",
    "mp3": "audio/mpeg",
    "ogg": "audio/ogg",
    "ra": "audio/x-realaudio",
    "3gp": "video/3gpp",
    "mpg": "video/mpeg",
    "mov": "video/quicktime",
    "flv": "video/x-flv",
    "mng": "video/x-mng",
    "asf": "video/x-ms-asf",
    "wmv": "video/x-ms-wmv",
    "avi": "video/x-msvideo",
    "mp4": "video/mp4",
  }
  const fileExtension = fext.toLowerCase();
  return types[fileExtension] ? types[fileExtension] : "";
};

export function downloadURI(uri: string, name: string): void {
  fetch(uri)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
    })
    .catch(console.error);
}

export const getHandleOnKeyUp = (ref: React.RefObject<HTMLDivElement>) => (evt: KeyboardEvent): void => {
  const { keyCode } = evt;
  if (keyCode === 13) {
    if (ref && ref.current) {
      let inputs = Array.from(ref.current.querySelectorAll("input")).filter(elem => !elem.disabled);
      let currentInputIdx = inputs.findIndex(elem => elem === evt.target);
      if (inputs.length && currentInputIdx < inputs.length) {
        if (currentInputIdx > -1) {
          if (!inputs[currentInputIdx].value) {
            return;
          }
        }
        if (currentInputIdx < inputs.length - 1) {
          inputs[currentInputIdx+1].focus(); 
        }
      }
    }
  }
}

export const hashCode = (s: string): number => {
  return s.split("").reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);
}