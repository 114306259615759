import React, { ReactNode, useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

import { palette } from "./palette";
import { shadows } from "./shadows";
import { overrides } from "./overrides";
import { CustomTypography, typography } from "./typography";
import { customShadows } from "./custom-shadows";
import { CustomPalette } from "./palette";
import { CustomShadows } from "./custom-shadows";

// ----------------------------------------------------------------------
declare module "@mui/material/styles" {
  interface Theme {
    customShadows: CustomShadows;
    palette: CustomPalette;
    typography: CustomTypography;
  }
  interface PaletteColor {
    lighter?: string;
    light: string;
    main: string;
    dark: string;
    darker?: string;
    contrastText: string;
  }
  interface Palette {
    disabled: Palette["primary"];
  }
  interface PaletteOptions {
    disabled?: PaletteOptions["primary"];
  }
  interface BreakpointOverrides {
    xxl: true;
  }
}

declare module "@mui/material/Button" { 
  interface ButtonPropsColorOverrides {
    disabled: true;
  }
}
declare module "@mui/material/Chip" { 
  interface ChipPropsColorOverrides {
    disabled: true;
  }
}
declare module "@mui/material/Typography" { 
  interface TypographyPropsVariantOverrides {
    mono: true;
  }
}

declare module "@mui/material/IconButton" { 
  interface IconButtonPropsColorOverrides {
    "grey.800": true;
    disabled: true;
  }
}



export default function ThemeProvider({ children }: { children: ReactNode }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1800,
        },
      },
    }),
    [],
  );

  const theme = createTheme(memoizedValue);

  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
