import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const ekycSlice = createSimpleTablePageSlice("ekyc");

export const selectEkycState = createSimpleTablePageSelector("ekyc");

export const { setQueryParams } = ekycSlice.actions;

export default ekycSlice.reducer;
