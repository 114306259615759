import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSetQueryParamsReducer } from "./common";
import type { DetailInputSchemaField } from "components/detail-dialog/item/input-schema"; 
import type { TaskInputParameter } from "services/task-service";

interface TaskJobState {
  taskName: string;
  queryParams: Record<string, any>;
  jobQueryParams: Record<string, any>;
  inputSchema: TaskInputParameter[];
  jobId: string | null;
  jobExecutionHistoryQueryParams: Record<string, any>;
}

export interface SetTaskPayload {
  taskName: string;
  inputSchema: TaskInputParameter[];
}

const initialState: TaskJobState = {
  taskName: "",
  queryParams: {},
  jobQueryParams: {},
  inputSchema: [],
  jobId: null,
  jobExecutionHistoryQueryParams: {},
};

export const taskJobSlice = createSlice({
  name: "taskJob",
  initialState,
  reducers: {
    setQueryParams: createSetQueryParamsReducer(),
    setTaskName: (state, action: PayloadAction<string>) => {
      state.taskName = action.payload;
    },
    setJobQueryParams: createSetQueryParamsReducer("jobQueryParams"),
    setTask: (state, action: PayloadAction<SetTaskPayload>) => {
      state.taskName = action.payload.taskName ?? "";
      state.inputSchema = action.payload.inputSchema ?? [];
    },
    setJobId: (state, action: PayloadAction<string>) => {
      state.jobId = action.payload;
    },
    setJobExecutionHistoryQueryParams: createSetQueryParamsReducer("jobExecutionHistoryQueryParams"),
  },
});

const selectTaskJob = (state: { taskJob: TaskJobState }) => state.taskJob;

export const selectTaskJobState = createSelector(
  [selectTaskJob],
  (s: TaskJobState) => {
    return {
      queryParams: s.queryParams,
      jobParams: {
        ...s.jobQueryParams,
        taskName: s.taskName,
      },
      jobQueryParams: s.jobQueryParams,
      jobExecutionHistoryQueryParams: s.jobExecutionHistoryQueryParams,
      jobId: s.jobId,
      taskName: s.taskName,
      inputSchema: s.inputSchema,
    };
  },
);

export const { setQueryParams, setJobQueryParams, setTask, setJobId, setJobExecutionHistoryQueryParams } =
  taskJobSlice.actions;

export default taskJobSlice.reducer;
