import {
  createTablePageWithEditedDetailSlice,
  createTablePageWithEditedDetailSelector,
} from "./common";

export const adminGroupManagementSlice = createTablePageWithEditedDetailSlice(
  "adminGroupManagement"
);

export const selectAdminGroupManagementState =
  createTablePageWithEditedDetailSelector("adminGroupManagement");

export const { setQueryParams, saveEditedDetail, removeEditedDetail } =
  adminGroupManagementSlice.actions;

export default adminGroupManagementSlice.reducer;
