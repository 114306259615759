import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const systemResourceSlice = createSimpleTablePageSlice("systemResource");

export const selectSystemResourceState = createSimpleTablePageSelector("systemResource");

export const {
  setQueryParams,
} = systemResourceSlice.actions;

export default systemResourceSlice.reducer;
