exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-application-[id]-tsx": () => import("./../../../src/pages/application/[id].tsx" /* webpackChunkName: "component---src-pages-application-[id]-tsx" */),
  "component---src-pages-application-index-tsx": () => import("./../../../src/pages/application/index.tsx" /* webpackChunkName: "component---src-pages-application-index-tsx" */),
  "component---src-pages-binding-authentication-history-tsx": () => import("./../../../src/pages/binding/authentication-history.tsx" /* webpackChunkName: "component---src-pages-binding-authentication-history-tsx" */),
  "component---src-pages-binding-registered-device-tsx": () => import("./../../../src/pages/binding/registered-device.tsx" /* webpackChunkName: "component---src-pages-binding-registered-device-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-client-index-tsx": () => import("./../../../src/pages/client/index.tsx" /* webpackChunkName: "component---src-pages-client-index-tsx" */),
  "component---src-pages-ekyc-[id]-tsx": () => import("./../../../src/pages/ekyc/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-[id]-tsx" */),
  "component---src-pages-ekyc-index-tsx": () => import("./../../../src/pages/ekyc/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-index-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-management-admin-group-tsx": () => import("./../../../src/pages/management/admin-group.tsx" /* webpackChunkName: "component---src-pages-management-admin-group-tsx" */),
  "component---src-pages-management-admin-tsx": () => import("./../../../src/pages/management/admin.tsx" /* webpackChunkName: "component---src-pages-management-admin-tsx" */),
  "component---src-pages-messaging-messaging-history-tsx": () => import("./../../../src/pages/messaging/messaging-history.tsx" /* webpackChunkName: "component---src-pages-messaging-messaging-history-tsx" */),
  "component---src-pages-messaging-messaging-template-tsx": () => import("./../../../src/pages/messaging/messaging-template.tsx" /* webpackChunkName: "component---src-pages-messaging-messaging-template-tsx" */),
  "component---src-pages-messaging-new-message-tsx": () => import("./../../../src/pages/messaging/new-message.tsx" /* webpackChunkName: "component---src-pages-messaging-new-message-tsx" */),
  "component---src-pages-product-[code]-tsx": () => import("./../../../src/pages/product/[code].tsx" /* webpackChunkName: "component---src-pages-product-[code]-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-resources-system-resource-[category]-index-tsx": () => import("./../../../src/pages/resources/system-resource/[category]/index.tsx" /* webpackChunkName: "component---src-pages-resources-system-resource-[category]-index-tsx" */),
  "component---src-pages-resources-system-resource-[category]-resource-key-tsx": () => import("./../../../src/pages/resources/system-resource/[category]/[resourceKey].tsx" /* webpackChunkName: "component---src-pages-resources-system-resource-[category]-resource-key-tsx" */),
  "component---src-pages-resources-system-resource-index-tsx": () => import("./../../../src/pages/resources/system-resource/index.tsx" /* webpackChunkName: "component---src-pages-resources-system-resource-index-tsx" */),
  "component---src-pages-system-service-configuration-tsx": () => import("./../../../src/pages/system/service-configuration.tsx" /* webpackChunkName: "component---src-pages-system-service-configuration-tsx" */),
  "component---src-pages-system-tenant-information-tsx": () => import("./../../../src/pages/system/tenant-information.tsx" /* webpackChunkName: "component---src-pages-system-tenant-information-tsx" */),
  "component---src-pages-system-tenanted-service-health-tsx": () => import("./../../../src/pages/system/tenanted-service-health.tsx" /* webpackChunkName: "component---src-pages-system-tenanted-service-health-tsx" */),
  "component---src-pages-task-execution-history-tsx": () => import("./../../../src/pages/task/execution-history.tsx" /* webpackChunkName: "component---src-pages-task-execution-history-tsx" */),
  "component---src-pages-task-task-job-tsx": () => import("./../../../src/pages/task/task-job.tsx" /* webpackChunkName: "component---src-pages-task-task-job-tsx" */)
}

