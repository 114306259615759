import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { parseJwt, loadState } from "utils";

interface AuthState {
  tenantId: number | null;
  adminId: number | null;
  username: string | null;
  atok: string | null;
  exp: number | null;
  scope: string[];
  adminName: string;
  emailAddress: string;
  passwordExpired: boolean;
  t: number;
}

interface setAuthPayload {
  accessToken: string;
  passwordExpired?: boolean;
}

interface setUserDetailPayload {
  emailAddress: string;
  adminName: string;
  changePasswordRequired: boolean;
}

export const defaultState: AuthState = {
  tenantId: null,
  adminId: null,
  username: null,
  atok: null,
  exp: null,
  scope: [],
  adminName: "",
  emailAddress: "",
  passwordExpired: false,
  t: 0,
};
const storedState = loadState("auth", Object.keys(defaultState)) as AuthState;
const IsExpiringTime = 300;

export const setT = createAsyncThunk("auth/setT", async (_, { getState }) => {
  const promise = new Promise<RootState>((resolve, reject) => {
    resolve(getState() as RootState);
  });
  return promise;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: Object.keys(storedState).length ? storedState : defaultState,
  reducers: {
    setAuth: (state, action: PayloadAction<setAuthPayload>) => { 
      state.atok = action.payload.accessToken;
      const content = parseJwt(state.atok);
      state.exp = parseInt(content.exp);
      state.scope = content.scope ? content.scope.split(" ") : [];
      state.tenantId = content.tid;
      state.adminId = content.uid;
      state.username = content.uname;
      // state.passwordExpired = !!action.payload.passwordExpired;
    },
    clearAuth: (state) => {
      state.tenantId = null;
      state.adminId = null;
      state.username = null;
      state.atok = null;
      state.exp = null;
      state.scope = [];
      state.adminName = "";
      state.emailAddress = "";
    },
    setUserDetail: (state, action: PayloadAction<setUserDetailPayload>) => {
      state.emailAddress = action.payload.emailAddress ?? "";
      state.adminName = action.payload.adminName ?? "";
      // if (action.payload.changePasswordRequired !== undefined) {
      //   state.passwordExpired = action.payload.changePasswordRequired;
      // }
    },
    // setT: (state) => {
    //   state.t = Number(moment().unix());
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(setT.fulfilled, (state, action) => {
      state.t = Number(moment().unix());
    });
  }
});
    

const selectAuth = (state: { auth: AuthState }) => state.auth;
export const selectAuthState = createSelector([selectAuth], (s: AuthState) => {
  const isATokNotExpired = s.exp !== null ? s.exp - s.t > 0 : false;
  return {
    tenantId: s.tenantId,
    adminId: s.adminId,
    username: s.username,
    atok: s.atok,
    scope: s.scope,
    adminName: s.adminName,
    emailAddress: s.emailAddress,
    isAuthenticated: !!s.atok && isATokNotExpired,
    refreshRequired: !!s.atok && !isATokNotExpired,
    // passwordExpired: s.passwordExpired,
    // t: s.t,
  };
});

export const { setAuth, clearAuth, setUserDetail } = authSlice.actions;

export default authSlice.reducer;
