import "./src/styles/global.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/300.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/roboto-mono/700.css';

import wrapWithProvider from "./wrap-with-provider";
import type { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return wrapWithProvider({ element });
};
