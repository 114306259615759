import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const executionHistorySlice =
  createSimpleTablePageSlice("executionHistory");

export const selectExecutionHistoryState =
  createSimpleTablePageSelector("executionHistory");

export const { setQueryParams } = executionHistorySlice.actions;

export default executionHistorySlice.reducer;
