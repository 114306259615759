import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { createSetQueryParamsReducer } from "./common";
import { DocumentType, DocumentStatus } from "services/application-types";

// type ApplicationDocumentStatus = "UPLOADED" | "SUBMITTED" | "DELETED";

export interface DocumentImagesItem {
  label: string;
  documentTypeLabel: string;
  key: string;
  documentType: DocumentType
  documentStatus: DocumentStatus,
  createTime: string;
  lastUpdateTime: string;
  lastUpdateAdmin: string,
  fileName?: string;
  src?: string;
}

interface ApplicationState {
  queryParams: Record<string, any>;
  ekycReqId: string | null;
  ekycHistoryQueryParams: Record<string, any>;
  documentImages: Record<string, DocumentImagesItem>;
  currentDocumentId: number;
  currentAddressProofId: number;
}

interface setDocumentImagesItemPayload {
  key: string;
  item: DocumentImagesItem;
}

const initialState: ApplicationState = {
  queryParams: {},
  ekycReqId: null,
  ekycHistoryQueryParams: {},
  documentImages: {},
  currentDocumentId: -1,
  currentAddressProofId: -1,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setQueryParams: createSetQueryParamsReducer("queryParams"),
    setEkycHistoryQueryParams: createSetQueryParamsReducer("ekycHistoryQueryParams"),
    setEkycReqId: (state, action: PayloadAction<string>) => {
      state.ekycReqId = action.payload;
    },
    setCurrentDocumentId: (state, action: PayloadAction<number>) => {
      state.currentDocumentId = action.payload;
    },
    setCurrentAddressProofId: (state, action: PayloadAction<number>) => {
      state.currentAddressProofId = action.payload;
    },
    setDocumentImagesItem: (state, action: PayloadAction<setDocumentImagesItemPayload>) => {
      const { key, item } = action.payload;
      state.documentImages[key] = item;
    },
    resetDocumentImages: (state) => {
      state.documentImages = {};
    },
  },
});

const selectApplication = (state: { application: ApplicationState }) => state.application;

export const selectApplicationState = createSelector(
  [selectApplication],
  (s: ApplicationState) => {
    return {
      queryParams: s.queryParams,
      ekycReqId: s.ekycReqId,
      ekycHistoryQueryParams: s.ekycHistoryQueryParams,
      documentImages: s.documentImages,
      currentDocumentId: s.currentDocumentId,
      currentDocument: s.documentImages[s.currentDocumentId],
      currentAddressProofId: s.currentAddressProofId,
    };
  }
);

export const {
  setQueryParams,
  setEkycHistoryQueryParams,
  setEkycReqId,
  setDocumentImagesItem,
  resetDocumentImages,
  setCurrentDocumentId,
  setCurrentAddressProofId,
} = applicationSlice.actions;

export default applicationSlice.reducer;
