import React, { ReactElement } from "react";
import ThemeProvider from "./src/theme";
import { Provider } from "react-redux";
import Store from "./src/state/store";

interface WrapperProps {
  element: ReactElement;
}

// wrapRootElement only accept element Element
const wrapper = ({ element }: WrapperProps): React.ReactElement => {
  return (
    <Provider store={Store}>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </Provider>
  );
};

export default wrapper;