import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const clientSlice = createSimpleTablePageSlice("client");

export const selectClientState = createSimpleTablePageSelector("client");

export const { setQueryParams } = clientSlice.actions;

export default clientSlice.reducer;

