import {
  createSimpleTablePageSelector,
  createSimpleTablePageSlice,
} from "./common";
import type { MessagingHistorySearchCriteria } from "services/messaging-history-service";

export const messagingHistorySlice =
  createSimpleTablePageSlice<MessagingHistorySearchCriteria>("messagingHistory");
export const selectMessagingHistoryState =
  createSimpleTablePageSelector<MessagingHistorySearchCriteria>("messagingHistory");

export const { setQueryParams } =
  messagingHistorySlice.actions;

export default messagingHistorySlice.reducer;
