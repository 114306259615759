import {
  configureStore,
  combineReducers,
  createAction,
} from "@reduxjs/toolkit";
import type {
  Middleware,
  MiddlewareAPI,
  Dispatch,
  Action,
} from "@reduxjs/toolkit";
import authReducer, { defaultState, setT } from "./authSlice";
import adminManagementReducer from "./adminManagementSlice";
import adminGroupManagementReducer from "./adminGroupManagementSlice";
import applicationReducer from "./applicationSlice";
import productReducer from "./productSlice";
import clientReducer from "./clientSlice";
import deviceRegistrationReducer from "./deviceRegistrationSlice";
import bindingAuthHistoryReducer from "./bindingAuthHistorySlice";
import messagingHistoryReducer from "./messagingHistorySlice";
import messagingTemplateReducer from "./messagingTemplateSlice";
import newMessageReducer from "./newMessageSlice";
import ekycReducer from "./ekycSlice";
import executionHistoryReducer from "./executionHistorySlice";
import taskJobReducer from "./taskJobSlice";
import tenantedServiceHealthReducer from "./tenantedServiceHealthSlice";
import historyReducer from "./histSlice";
import systemResourceReducer from "./systemResourceSlice";
import navReducer from "./navSlice";
import { removeState } from "utils";

// const updateAuthMiddleware: Middleware<(action: typeof setT) => number | Promise<RootState>, RootState> =
//   (storeAPI) =>
//   (next) =>
//   (action) => {
//     if (setT.match(action)) {
//       const promise = new Promise((resolve, reject) => {
//         next(action);
//         resolve(Store.getState());
//       });
//       return promise;
//     }
//     return next(action);
//   };
// 
export const resetStore = createAction<number>("resetStore");
const appReducer = combineReducers({
  history: historyReducer,
  auth: authReducer,
  adminManagement: adminManagementReducer,
  adminGroupManagement: adminGroupManagementReducer,
  application: applicationReducer,
  product: productReducer,
  client: clientReducer,
  deviceRegistration: deviceRegistrationReducer,
  bindingAuthHistory: bindingAuthHistoryReducer,
  messagingHistory: messagingHistoryReducer,
  messagingTemplate: messagingTemplateReducer,
  newMessage: newMessageReducer,
  executionHistory: executionHistoryReducer,
  tenantedServiceHealth: tenantedServiceHealthReducer,
  taskJob: taskJobReducer,
  ekyc: ekycReducer,
  systemResource: systemResourceReducer,
  nav: navReducer,
});

export type RootState = ReturnType<typeof appReducer> | undefined;

const rootReducer = (state: RootState, action: Action) => {
  // Before handled by reducer slices
  if (action.type === "resetStore" || action.type === "auth/clearAuth") {
    // Clear sessionStorage
    removeState("auth", Object.keys(defaultState));
  }
  if (action.type === "resetStore") {
    if (process.env.NODE_ENV === "development") {
      console.log("Reset store.");
    }
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const Store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().prepend(updateAuthMiddleware),
});

if (process.env.NODE_ENV === "development") {
  Store.subscribe(() => {
    console.log("State After dispatch: ", Store.getState());
  });
}

export default Store;
