import { createSlice } from "@reduxjs/toolkit";
import { setAuth, clearAuth } from "./authSlice";

const historyRegisteredActions = [setAuth, clearAuth];

interface HistoryState {
  lastAction: string | null;
  lastLoc: string;
};

const initialState: HistoryState = {
  lastAction: null,
  lastLoc: "",
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Register action that could be shown in history/lastAction
    historyRegisteredActions.forEach((a) => {
      builder.addCase(a, (state) => {
        state.lastAction = a.type;
      });
    });
  },
});

export default historySlice.reducer;
