import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";

export type QueryParamsPayload = Record<string, any>;

export const createSetQueryParamsReducer =
  <Q = QueryParamsPayload>(key: string = "queryParams") =>
  (state: Record<string, any>, action: PayloadAction<Q>) => {
    state[key] = action.payload;
  };

export type SetQueryParamsAction<Q = QueryParamsPayload> = (
  payload: Q 
) => PayloadAction<Q>;
interface EditedDetailPayload {
  detailId: string | number;
  stateMap: Record<string, any>;
  detailData: Record<string, any>;
}

export type SaveEditedDetailAction = (
  payload: EditedDetailPayload
) => PayloadAction<EditedDetailPayload>;
export const saveEditedDetailReducer = (
  state: Record<string, any>,
  action: PayloadAction<EditedDetailPayload>
) => {
  let detailId = action.payload.detailId;
  let stateMap = action.payload.stateMap;
  let detailData = action.payload.detailData;
  state.editedDetails[String(detailId)] = {
    stateMap,
    detailData,
  };
};

type RemoveEditedDetailPayload = string | number;

export const removeEditedDetailReducer = (
  state: Record<string, any>,
  action: PayloadAction<RemoveEditedDetailPayload>
) => {
  let detailId = action.payload;
  let { [String(detailId)]: _, ...rest } = state.editedDetails;
  state.editedDetails = rest;
};

// Slice for Simple Table Page
export interface SimpleTablePageState<Q = Record<string, any>> {
  queryParams: Q;
}

export const createSimpleTablePageSlice = <Q = Record<string, any>>(name: string) => {
  const initialState: SimpleTablePageState<Q> = {
    queryParams: {} as Q,
  };
  return createSlice({
    name,
    initialState,
    reducers: {
      setQueryParams: createSetQueryParamsReducer<Q>(),
    },
  });
};

export const createSimpleTablePageSelector = <Q = Record<string, any>>(name: string) => {
  const selectSlice = (state: { [name: string]: SimpleTablePageState<Q> }) =>
    state[name];
  return createSelector([selectSlice], (s: SimpleTablePageState<Q>) => {
    return {
      queryParams: s.queryParams,
    };
  });
};

// Slice for QueryParams + editedDetail Page
export interface TablePageWithEditedDetailState {
  queryParams: Record<string, any>;
  editedDetails: Record<
    string,
    { stateMap: Record<string, any>; detailData: Record<string, any> }
  >;
}

export const createTablePageWithEditedDetailSlice = (name: string) => {
  const initialState: TablePageWithEditedDetailState = {
    queryParams: {},
    editedDetails: {},
  };
  return createSlice({
    name,
    initialState,
    reducers: {
      setQueryParams: createSetQueryParamsReducer(),
      saveEditedDetail: saveEditedDetailReducer,
      removeEditedDetail: removeEditedDetailReducer,
    },
  });
};

export const createTablePageWithEditedDetailSelector = (name: string) => {
  const selectSlice = (state: {
    [name: string]: TablePageWithEditedDetailState;
  }) => state[name];
  return createSelector([selectSlice], (s: TablePageWithEditedDetailState) => {
    return {
      queryParams: s.queryParams,
      editedDetails: s.editedDetails,
    };
  });
};
