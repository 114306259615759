import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const bindingAuthHistorySlice =
  createSimpleTablePageSlice("bindingAuthHistory");

export const selectBindingAuthHistoryState =
  createSimpleTablePageSelector("bindingAuthHistory");

export const { setQueryParams } = bindingAuthHistorySlice.actions;

export default bindingAuthHistorySlice.reducer;
