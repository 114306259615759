import {
  createSimpleTablePageSelector,
  createSimpleTablePageSlice,
} from "./common";
import type { TemplateSearchCriteria } from "services/messaging-template-service";

export const messagingTemplateSlice =
  createSimpleTablePageSlice<TemplateSearchCriteria>("messagingTemplate");

export const selectMessagingTemplateState =
  createSimpleTablePageSelector<TemplateSearchCriteria>("messagingTemplate");

export const { setQueryParams } =
  messagingTemplateSlice.actions;

export default messagingTemplateSlice.reducer;
