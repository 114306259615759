import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const tenantedServiceHealthSlice = createSimpleTablePageSlice(
  "tenantedServiceHealth"
);

export const selectTenantedServiceHealthState = createSimpleTablePageSelector(
  "tenantedServiceHealth"
);

export const { setQueryParams } = tenantedServiceHealthSlice.actions;

export default tenantedServiceHealthSlice.reducer;
