import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const deviceRegistrationSlice =
  createSimpleTablePageSlice("deviceRegistration");

export const selectDeviceRegistrationState =
  createSimpleTablePageSelector("deviceRegistration");

export const { setQueryParams } = deviceRegistrationSlice.actions;

export default deviceRegistrationSlice.reducer;
