import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import navConfigNested from "components/layouts/config-nav";
import type { StandardObject } from "utils";

interface NavState {
  openStates: StandardObject<boolean>;
}

interface setOpenStatesPayload extends StandardObject<boolean> {}

const initialState: NavState = {
  openStates: navConfigNested.reduce(
    (ret: { [key: string]: boolean }, group) => {
      group.children.forEach((lv1Itm) => {
        if (lv1Itm.children && lv1Itm.children.length) {
          ret[lv1Itm.name] = false;
        }
      });
      return ret;
    },
    {}
  ),
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setOpenStates: (state, action: PayloadAction<setOpenStatesPayload>) => {
      state.openStates = {
        ...state.openStates,
        ...action.payload,
      };
    },
  },
});

const selectNav = (state: { nav: NavState }) => state.nav;

export const selectNavState = createSelector([selectNav], (s: NavState) => {
  return {
    openStates: s.openStates,
  };
});

export const { setOpenStates } = navSlice.actions;

export default navSlice.reducer;
