import {
  createSimpleTablePageSlice,
  createSimpleTablePageSelector,
} from "./common";

export const productSlice = createSimpleTablePageSlice("product");

export const selectProductState = createSimpleTablePageSelector("product");

export const { setQueryParams } = productSlice.actions;

export default productSlice.reducer;
